<template>
  <div>
    <v-btn
      small
      text
      class="py-5"
      tabindex="-1"
      @click="open"
    >
      <v-icon small>
        mdi-plus
      </v-icon>
      Загрузить Excel
    </v-btn>
    <v-overlay :value="show">
      <v-card
        v-click-outside="close"
        light
        class="pb-5"
        color="#333333"
        width="700"
      >
        <v-card-title class="white--text d-flex justify-space-between">
          <span>Загрузка Excel</span>
          <v-btn
            color="error"
            small
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="teal"
        />
        <br>
        <div class="white pa-5 rounded-0">
          <slot />
          <div class="d-flex justify-end">
            <v-btn
              color="#5CB7B1"
              :disabled="disabled"
              @click="submit"
            >
              Загрузить
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-overlay>
  </div>
</template>
<script>
import closeOnEscape from '@/mixins/close-on-escape.mixin'
export default {
  mixins:[closeOnEscape],
  props:{
    loading:{
      type:Boolean,
      required:true
    },
    disabled:{
      type:Boolean,
      required:true
    }
  },
  data:() => ({
    show:false,
  }),
  methods:{
    close(){
      this.$emit('close')
      this.show = false
    },
    open(){
      this.show = true
    },
    submit(){
      this.$emit('submit')
    }
  }
}
</script>

<style scoped>
.v-radio:last-child {
  margin-bottom: 0px!important;

}
</style>
