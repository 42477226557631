<template>
  <div class="d-flex align-center transition-box__params">
    <div
      class="transition-box__param font-weight-bold"
    >
      <span>{{ item.tn_ved }} <span class="teal--text">{{
        item.add_tn_ved
      }}</span></span>
    </div>
    <div class="transition-box__param">
      <p
        class="my-auto"
      >
        {{ title }}
      </p>
    </div>
    <div class="transition-box__param font-weight-bold">
      <span>{{
        shipmentDate
      }}</span>
    </div>
    <div
      class="transition-box__param text-center"
    >
      <span>{{ item.origin_country_letter }}</span>
    </div>
    <div class="transition-box__param text-end">
      <span>{{ weight }}</span>
    </div>
    <div class="transition-box__param text-end">
      <span>{{ item.invoice_cost }} {{ item.invoice_currency_letter }}</span>
    </div>
    <div class="transition-box__param font-weight-bold text-end pr-2">
      <span
        class="green--text darken-4"
      >{{ customsIndex }}</span>
    </div>
  </div>
</template>
<script>
import moment from "moment/moment";

export default {
  props: {
    item: {
      required: true,
      type: Object
    },
  },
  computed: {
    customsIndex() {
      const value = (this.item.statistical_cost / this.item.net_weight).toFixed(2)
      if (!isNaN(value) && this.item.statistical_cost && this.item.net_weight) {
        return `${value}`
      }
      return "0"
    },
    title() {
      if('title' in this.item){
        let title = this.item.title || ""
        return this.shorten(title)
      }else if('description' in this.item && 'specific_description' in this.item){
        const title = [this.item.description, this.item.specific_description].filter((v) => v).join('; ') || ""
        return this.shorten(title)
      }else {
        return ''
      }
    },
    shipmentDate() {
      return this.getShipmentDate(this.item.shipment_date)
    },
    weight() {
      const gross = this.item.gross_weight
      const net = this.item.net_weight
      const weight = [gross, net].filter(weightParam => !!weightParam).join('/')
      return weight ? `${weight} кг` : ''
    },
  },
  methods: {
    shorten(title = ""){
      return title.length - 1 > 105 ? `${title.slice(0, 105)} ...`: title
    },
    getShipmentDate(date) {
      return date ? moment(date).format('DD.MM.YYYY') : ""
    },
  },
}
</script>
<style scoped>

.transition-box__params {
  font-size: 12px;
  color: black;
  width: 100%;
  padding: 10px 0;
}

.transition-box__param {
  transition: all ease-in-out 0.3s;
}

.transportation-box__header_close .transition-box__param {
  visibility: visible;
}

.transportation-box__header_open .transition-box__param {
  visibility: hidden;
}

.transition-box__params div:nth-child(1) {
  flex: 3;
}

.transition-box__params div:nth-child(2) {
  flex: 21;
}

.transition-box__params div:nth-child(3) {
  flex: 2;
}

.transition-box__params div:nth-child(4) {
  flex: 1;
}

.transition-box__params div:nth-child(5) {
  flex: 3;
}

.transition-box__params div:nth-child(6) {
  flex: 3;
}

.transition-box__params div:nth-child(7) {
  flex: 2;
}

.transition-box__params div:nth-child(8) {
  flex: 1;
}

</style>