<template>
  <div>
    <v-btn
      small
      text
      class="py-5"
      tabindex="-1"
      @click="download"
    >
      <v-icon small>
        mdi-file-excel-box-outline
      </v-icon> Скачать Excel
    </v-btn>
  </div>
</template>
<script>
import { downloadExcel, getFilenameFromContentDisposition} from '@/helpers/documents'

export default {
  inject:['rootNamespace'],
  methods:{
    download(){
      const id = this.$route.params.id
      this.$store.dispatch(`${this.rootNamespace}/downloadExcel`, id).then(res => {
        const name = getFilenameFromContentDisposition(res)
        downloadExcel(res.data, name)
      }).catch(() => {
        this.$snackbar({text:'Не удалось сохранить файл', color:'red', top:false})
      })
    }
  }
}
</script>
