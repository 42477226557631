import UploadExcelParams from "@/components/documents/upload-excel/upload-excel-params.vue";
import UploadExcelModal from "@/components/documents/upload-excel/upload-excel-modal.vue";

export default {
  components: {UploadExcelParams, UploadExcelModal},
  inject:['rootNamespace'],
  computed: {
    shipmentIndex(){
      return this.$store.getters[`${this.rootNamespace}/getShipmentIndex`] ?? null
    },
    wares(){
      return this.shipmentIndex === null
        ? this.$store.getters[`${this.rootNamespace}/getSelectedWares`]
        : this.selected?.ware_shipments?.at(this.shipmentIndex)?.wares ?? []
    },
    selected(){
      return this.$store.getters[`${this.rootNamespace}/getSelected`]
    },
    disabled() {
      if(!this.file) return true
      return this.wares.length ? this.includesNull(this.params) : this.includesNull(this.notRequiringWaresParams)
    },
    notRequiringWaresParams(){
      return this.params.filter(param => !param.requiresWares)
    },
    filteredParams:{
      get(){
        return this.wares.length ? this.params : this.notRequiringWaresParams
      },
      set(value){
        value.forEach(param => {
          const index = this.params.findIndex(p => p.id === param.id)
          this.params.splice(index, 1, param)
        })
      }
    }
  },
  methods: {
    getShipmentId(){
      return this.selected?.ware_shipments?.at(this.shipmentIndex)?.id ?? null
    },
    preparedParams(){
      return this.params.reduce((prev, curr) => {
        return {...prev, [curr.id]: curr.value === null ? false : curr.value}
      }, {})
    },
    includesNull(params){
      return params.some(param => param.value === null)
    },
    uploadErrorHandler(err) {
      const text = err?.response?.data || "Ошибка"
      this.$snackbar({text, color: "red", top: false, right: false, timeout: 5000});
      throw err
    },
    uploadExcel(payload) {
      this.loading = true
      return this.$store.dispatch(`${this.rootNamespace}/uploadExcel`, payload).then(() => {
        this.$success('Товары добавлены')
      }).catch(this.uploadErrorHandler).finally(() => this.loading = false)
    }
  }
}
