<template>
  <v-overlay
    :value="show"
  >
    <v-card
      v-click-outside="close"
      light
      class="pb-5"
      color="#333333"
      width="1300"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <span> Выбор товаров</span>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="teal"
        class="my-3"
      />
      <div class="white pa-5 rounded-0">
        <v-row>
          <v-col
            cols="2"
          >
            <div class="d-flex align-center pb-3">
              <v-checkbox
                v-model="withWares"
                color="grey darken-3"
                :label="`Товары (${selectedWares.length}/${wares.length})`"
                hide-details="true"
              />
            </div>
          </v-col>
          <v-col
            cols="10"
          >
            <v-alert
              v-if="isDisabled"
              class="mb-0"
              dense
              text
              color="orange"
              type="warning"
            >
              Выберите товары
            </v-alert>
          </v-col>
        </v-row>
        <div>
          <div class="d-flex my-4">
            <v-text-field
              ref="rangeField"
              v-model="range"
              hide-details="auto"
              background-color="#EDEDED"
              class="ba-0 rounded-l-sm rounded-r-0"
              filled
              rounded
              placeholder="Введите диапазон"
              @keyup.enter="getIdsFromRange"
            />
            <v-btn
              height="auto"
              class="elevation-0 my-0 rounded-l-0"
              x-large
              dark
              min-width="50px"
              @click="getIdsFromRange"
            >
              <v-icon>mdi-plus</v-icon>
              <span class="d-none d-lg-block">Выбрать</span>
            </v-btn>
          </div>
          <div
            class="elements__table"
          >
            <v-virtual-scroll
              ref="wares"
              style="overflow-y: scroll"
              :items="wares"
              :bench="10"
              :item-height="50"
              :height="height"
            >
              <template #default="{index,item}">
                <copy-field-element
                  :headers="headers"
                  :item="item"
                  :index="index"
                  :selected="selectedWares.includes(item.id)"
                  @select="onItemSelect"
                />
              </template>
            </v-virtual-scroll>
          </div>
        </div>


        <div class="d-flex justify-end mt-4">
          <v-btn
            elevation="0"
            color="#EDEDED"
            class="mr-2"
            @click="unset({cancel:true})"
          >
            Отмена
          </v-btn>
          <v-btn
            id="applyButton"
            :disabled="isDisabled"
            color="#5CB7B1"
            elevation="0"
            :dark="!isDisabled"
            @click="submit"
          >
            Применить
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import {isGoodsRangeValid, rangeToArray , isCorrectStartEnd, trimRange} from '@/helpers/inputs'
import CopyFieldElement from './copy-field-element.vue';
import {applyToWares} from "@/events/common";
import closeOnEscapeMixin from "@/mixins/close-on-escape.mixin";
import {convertEmptyStringsToNull} from "@/helpers/objects";

export default {
  components:{
    CopyFieldElement,
  },
  mixins:[closeOnEscapeMixin],
  inject:["rootNamespace"],
  props:{
    headers:{
      required:true,
      type:Array,
    },
    wares:{
      required:true,
      type:Array,
    },
    waresParams:{
      required:true,
      type:Object,
    },
    show:{
      required:true,
      type:Boolean
    }
  },
  data:() => ({
    loading:false,
    blocks:[
      {
        name:"Импортер/Экспортер",
        value:"subject"
      },
      {
        name:"Контрагент",
        value:"counter_agent"
      },
      {
        name:"Документы",
        value:"presented_documents"
      },
    ],
    withWares:false,
    selectedWares:[],
    range:"",
    validRange:""
  }),
  computed:{
    requests(){
      return this.$store.getters[`${this.rootNamespace}/getRequests`]
    },
    selected(){
      return this.$store.getters[`${this.rootNamespace}/getSelected`]
    },
    height(){
      return window.innerHeight - 500
    },
    isDisabled(){
      return !this.selectedWares.length
    },
    shipmentIndex(){
      return this.$store.getters[`${this.rootNamespace}/getShipmentIndex`] ?? null
    },
    updateInShipment(){
      if(this.selected){
        return 'ware_shipments' in this.selected
      }
      return false
    }
  },
  watch:{
    show(value){
      if(value) this.onModalOpen()
    },
    range(nv) {
      this.validateRange(nv.replace(".", ","));
    },
    withWares(nv){
      if(nv === true){
        this.selectedWares = this.wares.map(i => i.id)
      }else{
        this.selectedWares = []
      }
    },
  },
  beforeDestroy(){
    this.unset()
  },
  methods:{
    scrollToWare(index){
      if(index === null) return;
      const container = this.$refs.wares.$el
      container.scrollTop = index * 50
    },
    onModalOpen(){
      Promise.all(this.requests)
        .then(() => this.$nextTick())
        .then(this.focusOnRangeInput)
        .then(() => this.scrollToWare(this.waresParams.index))
        .catch(() => this.$error())
    },
    focusOnRangeInput(){
      this.$refs.rangeField.focus()
      return Promise.resolve()
    },
    getText(err){
      return typeof err === 'object' &&'response' in err ? err.response : (err ||"Ошибка" )
    },
    submit(){
      const UPDATE_ACTION_NAME = this.updateInShipment ? 'applyToSeveralInShipment': 'applyToSeveral'
      const DELETE_ACTION_NAME = this.updateInShipment ? 'deleteGoodsAmountInShipment': 'deleteGoodsAmount'
      if(this.waresParams.action === 'apply'){
        if(Array.isArray(this.waresParams.field)){
          const values = {}
          this.waresParams.field.forEach(key => {
            values[key] = this.waresParams.value[key]
          })
          const payload = convertEmptyStringsToNull({
            ids: this.selectedWares,
            values
          })
          this.$store.dispatch(`${this.rootNamespace}/${UPDATE_ACTION_NAME}`, payload).then(() => {
            this.success()
          }).catch(err => {
            this.error(err)
          })
        }else{
          const payload = convertEmptyStringsToNull({
            ids: this.selectedWares,
            values: {[this.waresParams.field]: this.waresParams.value}
          })
          this.$store.dispatch(`${this.rootNamespace}/${UPDATE_ACTION_NAME}`, payload).then(() => this.success())
            .catch(err => this.error(err))
        }
      }else if(this.waresParams.action === 'apply_group'){
        const payload = convertEmptyStringsToNull({
          ids: this.selectedWares,
          values: this.waresParams.body
        })
        this.$store.dispatch(`${this.rootNamespace}/${UPDATE_ACTION_NAME}`, payload).then(() => this.success())
          .catch(err => this.error(err))

      }else if(this.waresParams.action === 'delete'){
        this.$store.dispatch(`${this.rootNamespace}/${DELETE_ACTION_NAME}`, { ids: this.selectedWares })
          .then(() => this.success())
          .catch(err =>this.error(err))
      }
    },
    success(){
      applyToWares.trigger()
      this.$snackbar({text:'Действие применено', top:false, right: false})
      this.unset()
      this.close()
    },
    error(err){
      this.$snackbar({text:this.getText(err), top:false, right: false, color:'red'})
    },
    validateRange(nv) {
      const result = isGoodsRangeValid(nv);
      if (result !== false) {
        this.validRange = result;
        this.range = result;
      } else {
        this.$nextTick(() => {
          this.range = this.validRange
        });
      }
    },
    getIdsFromRange(){
      this.range = trimRange(this.range)
      if(!isCorrectStartEnd(this.range)){
        return this.$snackbar({text:"Неверный диапазон", color:'red',top:false, right: false})
      }
      const idxs = rangeToArray(this.range, true)
      const values = idxs.map(idx => {
        return this.wares[idx]?.id
      })
      if(values.length > this.wares.length){
        this.range = ""
        return this.$snackbar({
          text:'Неверно указан диапазон. Кол-во выбранных товаров превышает имеющееся кол-во.',
          color:'red',
          top:false, right: false,
          timeout:5000,
        })
      }
      this.selectedWares = values
      // фокус на кнопку при нажатии Enter
      this.$nextTick(() => { document.querySelector("#applyButton").focus() })
    },
    unset({cancel = false} = {}){
      this.withWares = false
      this.loading = false
      this.range = ""
      if(cancel){
        this.onSelectAll()
      }else{
        this.selectedWares = []
      }
    },
    onSelectAll(){
      this.withWares ? this.selectedWares = this.wares.map(i => i.id) : this.selectedWares = []
    },
    onItemSelect(id){
      if(this.selectedWares.includes(id)){
        this.selectedWares = this.selectedWares.filter(i => i !== id)
      }else{
        this.selectedWares.push(id)
      }
    },
    close(){
      this.$emit('update:show', false)
    }
  }
}
</script>
