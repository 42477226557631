<template>
  <div
    v-intersect="onIntersect"
    class="transportation-box"
    :class="selectorClass"
  >
    <div
      class="transportation-box__header"
      :class="{
        'transportation-box__header_close': !show,
        'transportation-box__header_open': show,
      }"
    >
      <span
        class="font-weight-bold text-body-1 pr-2"
        @click.capture="toggle"
      >  {{ sortIndex }}</span>

      <div
        style="width: 100%"
        @click.capture="toggle"
      >
        <slot
          v-if="visible"
          name="title"
        />
      </div>
      <div class="d-flex">
        <v-btn
          max-height="32px"
          min-width="32px"
          max-width="32px"
          class="float-right ware-item-toggle"
          @focus.prevent
          @click.prevent="toggle"
        >
          <v-icon>
            {{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>

        <v-menu
          v-if="!disabledView"
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-btn
              tabindex="-1"
              max-height="32px"
              min-width="32px"
              max-width="32px"
              color="#5CB7B1"
              v-bind="attrs"
              class="ml-2 wares-item-menu"
              v-on="on"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <slot name="menu" />
        </v-menu>
      </div>
    </div>
    <div
      v-if="render"
      :id="id"
      v-click-outside="onOutside"
      class="editable-content"
    >
      <v-expand-transition>
        <div v-show="show">
          <slot
            name="body"
          />
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import {isOpenInjector} from "@/helpers/injectors";

export default {
  inject:['rootNamespace'],
  provide(){
    return{
      [isOpenInjector]:this.isOpen
    }
  },
  props:{
    index:{
      required:true,
      type:Number
    },
    sortIndex:{
      required:true,
      type:Number
    },
    id:{
      type:String,
      required:true
    },
    selectorClass:{
      type: String,
      required:true
    }
  },
  data:() =>({
    isIntersecting:false,
    show:false,
    render:false,
  }),
  computed: {
    visible() {
      return this.index < 16 || this.isIntersecting;
    },
    disabledView(){
      return this.$store.getters[`${this.rootNamespace}/getVisibility`]
    }
  },
  methods:{
    onOutside(event){
      this.$emit('outside', event)
    },
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting;
    },
    showBody(){
      this.show = true
    },
    hideBody(){
      this.show = false
    },
    onClose(){
      requestAnimationFrame(this.hideBody)
      this.$emit('toggle', false)
      this.$nextTick(() => {
        this.render = false
        this.$emit('toggle-tick', false)
      });
    },
    isOpen(){
      return this.show
    },
    onOpen(){
      this.$emit('toggle', true)
      this.render = true
      this.$nextTick(() => {
        this.$emit('toggle-tick', true)
        requestAnimationFrame(this.showBody)
      });
    },
    toggle() {
      this.render === true
        ? requestAnimationFrame(this.onClose)
        : requestAnimationFrame(this.onOpen)
    }
  },
};
</script>

<style scoped>
.transportation-box {
  border-radius: 5px;
  margin: 0 0 10px 0;
  cursor: pointer;
}

.transportation-box__header {
  padding: 0px 10px;
  align-items: center;
  display: flex;
  color: white;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.transportation-box__header_open {
  background: #333333;
  border-radius: 6px 6px 0px 0px;
}

.transportation-box__header_open span {
  color: white;
}

.transportation-box__header_close {
  background: #f2f2f2;
  border-radius: 6px;
}

.transportation-box__header_close span {
  color: #333333;
}


.transportation-box__header_close .transition-box__param {
  visibility: visible;
}

.transportation-box__header_open .transition-box__param {
  visibility: hidden;
}

.transition-box__params div:nth-child(1) {
  flex: 1;
}

.transition-box__params div:nth-child(2) {
  flex: 3;
}

.transition-box__params div:nth-child(3) {
  flex: 21;
}

.transition-box__params div:nth-child(4) {
  flex: 2;
}

.transition-box__params div:nth-child(5) {
  flex: 1;
}

.transition-box__params div:nth-child(6) {
  flex: 3;
}

.transition-box__params div:nth-child(7) {
  flex: 3;
}

.transition-box__params div:nth-child(8) {
  flex: 2;
}

.transition-box__params div:nth-child(9) {
  flex: 1;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
</style>