<template>
  <div
    :key="item.id"
    class="d-flex align-center table__row"
  >
    <div
      class="px-4 table__cell"
    >
      <v-checkbox
        :disabled="disabled"
        color="grey darken-3"
        :input-value="selected"
        hide-details
        @click="$emit('select', item.id)"
      />
    </div>
    <div
      v-for="header in headers"
      :key="`h-${index}-${header.value}`"
      :style="{
        width: header.width + 'px'
      }"
      class="table__cell"
    >
      <template v-if="header.value === 'tn_ved'">
        <div class="d-flex">
          <div class="mr-1">
            {{ item.tn_ved }}
          </div>
          <span class="teal--text">{{ item.add_tn_ved }}</span>
        </div>
      </template>
      <template v-else-if="header.value === 'title'">
        {{ cut(item.title) }}
      </template>
      <template v-else>
        {{ item[header.value] }}
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    item:{
      required:true,
      type:Object
    },
    index:{
      required:true,
      type:Number
    },
    selected:{
      required:true,
      type:Boolean
    },
    disabled:{
      required:false,
      type:Boolean
    },
    headers:{
      required:true,
      type:Array
    }
  },
  methods: {
    cut(title){
      return title && title.length > 100 ? title.slice(0,100) + '...' : title
    }
  }
}
</script>
<style scoped>
.table__row{
  height: 50px;
}
.table__cell{
  font-size: 14px;
  padding: 0 10px 0 0;
}
</style>