import {openGoodsModal} from "@/events/common";

export default {
  data(){
    return{
      show:false,
      preparedWares:[],
      waresParams:{
        action:null,
        field: null,
        value: null,
        index:null,
        filter:{}
      },
    }
  },
  created() {
    openGoodsModal.subscribe(this.onModalOpen)
  },
  beforeDestroy(){
    openGoodsModal.unsubscribe()
  },
}