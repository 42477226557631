export const OVERWRITE = {
  id:"overwrite",
  title:"Действие с загружаемыми товарами:",
  requiresWares:true,
  value:null,
  options:[
    {
      name:"Заменить текущие товары",
      value:true
    },
    {
      name:"Добавить к текущим товарам",
      value:false
    }
  ]
}

export const JOIN_ARTICLE_DESCRIPTION ={
  id:"joinArticleDescription",
  title:"Объединять ли разные наименования в один товар?",
  requiresWares:false,
  value:null,
  options:[
    {
      name:"Да",
      value:true
    },
    {
      name:"Нет",
      value:false
    }
  ]
}
export const UNKNOWN_COUNTRY ={
  id:"unknownCountry",
  title:"Для пустых значений страна происхождения установить \"00\"?",
  requiresWares:false,
  value:null,
  options:[
    {
      name:"Да",
      value:true
    },
    {
      name:"Нет",
      value:false
    }
  ]
}

export const JOIN_TRADE_MARK = {
  id:"joinTradeMark",
  title:"Объединять ли разные наименования товарных знаков в один ?",
  requiresWares:false,
  value:null,
  options:[
    {
      name:"Да",
      value:true
    },
    {
      name:"Нет",
      value:false
    }
  ]
}