<template>
  <div>
    <div
      v-for="(param) in value"
      :key="`group-${param.id}`"
    >
      <p>{{ param.title }}</p>
      <v-radio-group
        :value="param.value"
        @change="(newValue) => onChange(newValue, param)"
      >
        <v-radio
          v-for="option in param.options"
          :key="`group-${param.id}-${option.value}`"
          :label="option.name"
          :value="option.value"
          color="grey darken-3"
        />
      </v-radio-group>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  methods: {
    onChange(newValue, param) {
      const index = this.value.findIndex(p => p.id === param.id)
      const clonedValue = window.structuredClone(this.value)
      clonedValue.splice(index, 1, {
        ...param,
        value: newValue
      })
      this.$emit('input', clonedValue)
    }
  }
}
</script>
<style>

</style>