<template>
  <div class="mt-1">
    <v-chip
      v-for="(checkpoint,index) in checkpoints"
      :key="index"
      dark
      class="mr-1"
      small
      @click.prevent="scrollTo(checkpoint)"
    >
      {{ checkpoint }}
    </v-chip>
  </div>
</template>
<script>

import {getScrollOffset} from "@/helpers/control";

export default {
  inject:['rootNamespace'],
  data:() => ({
  }),
  computed:{
    selected(){
      return this.$store.getters[`${this.rootNamespace}/getSelected`]
    },
    selectedWares(){
      return this.$store.getters[`${this.rootNamespace}/getSelectedWares`]
    },
    shipmentIndex(){
      return this.$store.getters[`${this.rootNamespace}/getShipmentIndex`] ?? null
    },
    waresLength(){
      if(this.shipmentIndex !== null){
        return this.selected?.ware_shipments[this.shipmentIndex]?.wares?.length ?? 0
      }
      return this.selectedWares?.length ?? 0
    },
    checkpoints(){
      if(this.waresLength < 50) return []
      const last = this.waresLength
      const intermediate = this.waresLength / 5
      let indexes = []
      for(let i = 1; i < 5 ; i++){
        const value = this.generateNum(i * intermediate)
        indexes.push(value)
      }
      return [...indexes, last]
    }
  },
  methods:{
    generateNum(v) {
      return Math.ceil(v / 10) * 10
    },
    scrollTo(checkpoint){
      const waresCollection = document.querySelectorAll(`.${this.rootNamespace}-goods-item`)
      const ware = waresCollection[checkpoint - 1]
      this.$scrollTo(ware, 0, {
        offset:getScrollOffset(),
        force: true
      })
    },
  }

}
</script>