import moment from "moment";

export function prepareWaresParams({field, value, ...params}){
  if(Array.isArray(field)){
    const prepared = field.reduce((previous, currentKey, index) => {
      return {...previous, [currentKey]: value[index]}
    }, {})
    return {
      ...params,
      field,
      value: prepared,
    }
  }else{
    return {field, value, ...params}
  }
}

export const DEFAULT_TABLE_HEADERS = [
  {
    text:"№",
    value:"index",
    flex:1,
    width:60
  },
  {
    text:"Код товара",
    value:"tn_ved",
    flex:1,
    width:140
  },
  {
    text:"Наименование товара",
    value:"title",
    flex:18,
    width:500

  },
  {
    text:"Происх.",
    value:"origin_country_letter",
    flex:1,
    width:50
  },
  {
    text:"Нетто",
    value:"net_weight",
    flex:1,
    width:100
  },
  {
    text:"Брутто",
    value:"gross_weight",
    flex:1,
    width:100
  },
  {
    text:"Стоимость",
    value:"cost",
    flex:4,
    width:100
  },
  {
    text:"Дата отгрузки",
    value:"shipment_date",
    flex:1,
    width:110
  },
]

export function currentShipmentWares(selected, index){
  if(index === null) return []
  return selected.ware_shipments[index]?.wares ?? []
}
export function prepareWares(wares){
  return wares.map((item, idx) => {
    const {
      id,
      tn_ved,
      add_tn_ved,
      net_weight,
      gross_weight,
      invoice_cost,
      shipment_date: date,
      invoice_currency_letter,
      origin_country_letter,
      title: titleDefault = "",
    } = item
    const shipment_date = date ? moment(date).format('DD.MM.YYYY') : ''
    const title = titleDefault !== null && titleDefault.length > 120 ? titleDefault.slice(0, 130) + '...' : titleDefault
    const cost = invoice_cost && invoice_currency_letter ? `${invoice_cost} ${invoice_currency_letter}` : invoice_cost
    return {
      id,
      index:idx + 1,
      tn_ved,
      add_tn_ved,
      title,
      shipment_date,
      net_weight,
      gross_weight,
      invoice_cost,
      cost,
      invoice_currency_letter,
      origin_country_letter,
    }
  })
}