<template>
  <div>
    <v-btn
      small
      text
      class="py-5"
      tabindex="-1"
      @click="download"
    >
      <v-icon small>
        mdi-download
      </v-icon>
      Скачать спецификацию
    </v-btn>
  </div>
</template>
<script>
import {downloadExcel, getFilenameFromContentDisposition} from '@/helpers/documents'
export default {
  inject:['rootNamespace'],
  methods:{
    download(){
      this.$store.dispatch(`${this.rootNamespace}/loadTemplate`).then(res => {
        const {data} = res
        const name = getFilenameFromContentDisposition(res)
        downloadExcel(data, name)
      }).catch(() => this.$error())
    }
  }
}
</script>
