<template>
  <div>
    <v-btn
      class="side-menu-show rounded-r-0 elevation-3"
      @mouseenter="show = true"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <transition name="slide-fade">
      <v-card
        v-if="show"
        width="300"
        class="side-menu"
        @mouseleave="show=false"
      >
        <v-list>
          <template v-for="(item, index) in items">
            <v-divider
              v-if="item === null"
              :key="index"
            />
            <v-list-item
              v-else
              :key="index"
              link
              class="side-menu-item"
            >
              <v-list-item-content @click.capture="scrollTo(item.id)">
                {{ item.name }}
                <template v-if="item.component">
                  <component :is="item.component" />
                </template>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </transition>
  </div>
</template>
<script>
import {getScrollOffset} from "@/helpers/control";

export default {
  props:{
    items:{
      required:true,
      type:Array,
    }
  },
  data:() => ({
    show:false
  }),
  computed:{
    offsetTop(){
      return 0
    }
  },
  methods:{
    scrollTo(id){
      const offset = getScrollOffset()
      this.$scrollTo(`#${id}`, 200, {
        offset,
        force: true,
      })
    }
  }
}
</script>
<style scoped>
.side-menu{
  position: fixed;
  top: 90px;
  right: 0;
  z-index: 2;
  height: calc(100vh - 250px);
  background-color: white;
  overflow-y: scroll;
}
.side-menu::-webkit-scrollbar {
  display: none!important;
}
.side-menu-item{
  padding-top: 2px;
  padding-bottom: 2px;
}
.side-menu-item:hover{
  color: teal!important;
}
.side-menu-show{
  position: fixed;
  top: calc(50% - 45px);
  right: 0;
  z-index: 2;
  height: 90px!important;
  min-width: 32px!important;
  width: 32px!important;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>

